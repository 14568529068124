import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/layout/home' },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/views/layout'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home'),
        meta: { title: '首页' }
      },
      {
        path: 'culture',
        name: 'culture',
        component: () => import('@/views/culture'),
        meta: { title: '企业文化' }
      },
      {
        path: 'business',
        name: 'business',
        component: () => import('@/views/business'),
        meta: { title: '业务介绍' }
      },
      {
        path: 'target',
        name: 'target',
        component: () => import('@/views/target'),
        meta: { title: '发展目标' }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
